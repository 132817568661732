<template>
    <div class="card-banner common-t">
        <div class="left">
<!--            <Icon style="color: #ccc;font-size: 20px;margin-left: 15px;" :type="icon"/>-->
            <img v-if="type === 1" class="icon" src="@/assets/logo/hot.svg" alt="Hot SVG" />
            <img v-if="type === 2" class="icon" src="@/assets/logo/slots.svg" alt="Hot SVG" />
            <img v-if="type === 3" class="icon" src="@/assets/logo/live.svg" alt="Hot SVG" />
            <img v-if="type === 4" class="icon" src="@/assets/logo/sgame.svg" alt="Hot SVG" />
            <img v-if="type === 7" class="icon" src="@/assets/logo/table.svg" alt="Hot SVG" />
            <img v-if="type === 6" class="icon" style="height: 20px" src="@/assets/logo/fishing.png" alt="Hot SVG" />
            <img v-if="type === 8" class="icon" src="@/assets/logo/bingo.svg" alt="Hot SVG" />
            <img v-if="type === 5" class="icon" src="@/assets/logo/newRelease.svg" alt="Hot SVG" />
            <span class="l-txt">{{ txt }}</span>
        </div>
        <!--type 1 2 3-->
        <div class="right">
            <div class="arrL arr" style="margin-right: 20px"  @click="moveLR(1)">
                <Icon style="font-size: 16px" type="md-arrow-round-back" :class="{active:page === 1}" />
            </div>
            <div class="arrR arr" @click="moveLR(2)">
                <Icon style="font-size: 16px" type="md-arrow-round-forward" :class="{active:page === 2}" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "",
    data(){
        return{
            page : 1
        }
    },
    props: ['txt', 'icon', 'type'],
    methods: {
        handleMenuClick(name) {
            console.log(name)
        },
        moveLR(num){
            this.page = num
            this.$parent.moveLR(num)
        }
    }
}
</script>

<style scoped lang="scss">
.card-banner {
    padding: 10px 0 0 0;
    width: 96%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #fff;

    .left {
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;

        .icon{
            //width: 10px;
            margin: 0 4px 0 10px;
            height: 18px;
        }
        .l-txt {
            display: inline-block;
            text-indent: 4px;
        }
    }

    .right {
        display: flex;
        justify-content: space-evenly;
        margin-right: 10px;
        padding: 5px 10px;
        border: 1px solid #aaa;
        border-radius: 20px;
        position: relative;
        color: #aaa;

        .arr {
            display: flex;
            align-items: center;
        }

        .active {
            color: #fff;
        }
    }

    .right:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        background-color: #aaa;
        top: 0;
    }

    .gun {
        margin-right: 16px;
        border: none;
    }

    .gun:before {
        width: 0;
    }

}

/deep/ .ivu-dropdown {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: #0E212E;
}

/deep/ .ivu-dropdown-rel {
    a {
        color: #fff;
    }
}
</style>
