<script>
import NavTop from "@/components/NavTop.vue";
import GameCard from "@/components/Card.vue";

export default {
    name: "",
    components: {NavTop, GameCard},
    data() {
        return {
            list1: [],  //第一页
        }
    },
    props: ['gameListT1', 'imgBasePath', 'txt', 'loading', 'type'],
    watch: {
        gameListT1(newVal, old) {
            this.initList()
        }
    },
    // computed: {
    //     member() {
    //         return this.$store.state.member
    //     }
    // },
    methods: {
        moveLR(type, gameCategory) {
            const box = this.$refs.gameBox
            if (type === 1) box.scrollLeft = 0
            if (type === 2) box.scrollLeft = box.scrollWidth - box.clientWidth
            if (type === 3) this.$router.push({path: '/gameAllTing', query: {gameCategory}});   //去全部界面
        },
        initList() {
            this.gameListT1.forEach((item, i) => {
                if (i < 12) this.list1.push(item)
            })
            // if (this.gameListT1[0]) {  //如何存在至少一个  查看更多-就存在
                // this.list1.push({type: this.gameListT1[0].gameCategory})
            // }
        },
    },

}
</script>

<template>
    <div>
        <NavTop :txt="txt" :type="type" :icon="'ios-flame'"></NavTop>
        <div v-if="loading" class="loading">
            <Spin :color="'red'" :size="'large'"/>
        </div>
        <div v-else class="game-box" ref="gameBox">
            <div ref="gameList" class="game-list">
                <GameCard v-for="(item,i) in list1" :item="item" :key="i">
                </GameCard>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.loading {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/ .ivu-spin-dot {
        background-color: #fff;
        animation: ani-spin-bounce 2s 0s ease-in-out infinite;
    }
}

.game-box {
    width: 96%;
    height: auto;
    margin: 10px auto;
    overflow-x: scroll;

    .game-list {
        width: 200%;
        display: flex;
        flex-wrap: wrap;

    }
}

</style>
